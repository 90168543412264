
    import { computed, defineComponent, onMounted, ref } from "vue";
    import { useStore } from "vuex";
    import FullCalendar, {
      CalendarOptions,
      EventApi,
    } from "@fullcalendar/vue3";
    import dayGridPlugin from "@fullcalendar/daygrid";
    import timeGridPlugin from "@fullcalendar/timegrid";
    import listPlugin from "@fullcalendar/list";
    import moment from "moment";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

import CustomerService from "@/core/services/car/CustomerService";
    export default defineComponent({
      components: {
        FullCalendar,
      },
      data() {

        return {

        };
      },
      setup() {
        const store = useStore();
        const todayDate = moment().startOf("day");
        const YM = todayDate.format("YYYY-MM");
        const YESTERDAY = todayDate
          .clone()
          .subtract(1, "day")
          .format("YYYY-MM-DD");
        const TODAY = todayDate.format("YYYY-MM-DD");
        const TOMORROW = todayDate
          .clone()
          .add(1, "day")
          .format("YYYY-MM-DD");

        const tasks = computed(() => {
          const task = store.getters.getEventDetails;
          return task.map((e:any) => ({
            ...e,
            title: e.title + ' - ' + e.customer.name + '(' + e.customer.phone_number + ')'
          }))
        })

        const isOpen = ref(false);
        const selected = ref("");
        const title = ref("");
          // jira issue 296
          let calendarOptions = {
            plugins: [dayGridPlugin, timeGridPlugin, listPlugin],
            headerToolbar: {
              left: "prev,next today",
              center: "title",
              right:
                "dayGridMonth,listMonth",
            },
            selectable: true,
            selectMirror: true,
            

            eventClick: function (arg) {
              console.log(arg)
              isOpen.value = true;
              selected.value = arg.el.innerText
              title.value = moment(arg.event.startStr).format('DD/MM/YYYY')
            },

            events: tasks.value,

            height: 800,
            contentHeight: 780,
            aspectRatio: 3, // see: https://fullcalendar.io/docs/aspectRatio

            nowIndicator: true,
            now: TODAY + "T09:25:00", // just for demo

            views: {
              dayGridMonth: { buttonText: "month" },
              timeGridWeek: { buttonText: "week" },
              timeGridDay: { buttonText: "day" },
            },

            initialView: "dayGridMonth",
            initialDate: TODAY,

            editable: true,
            dayMaxEvents: true, // allow "more" link when too many events
            navLinks: true,
            eventTimeFormat: { hour: "numeric", minute: "2-digit" }
            
          } as CalendarOptions;
        const currentEvents =  [] as EventApi[];
        onMounted(() => {
            MenuComponent.reinitialization();
            setCurrentPageBreadcrumbs("Tasks Calendar View", []);
        })
        return{ 
          title,
          selected,
          isOpen,
          tasks,
          currentEvents,
          calendarOptions

        }
      }
    });
  